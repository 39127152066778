body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
  height: 100vh;
  width: 100vw;
}

.ui.button.buttonStyle{ 
  padding: 10px;
  background-color: rgba(204, 12, 12, 0);
  border-radius: 3;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 0.5px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
}

.ui.button.buttonStyle.green{ 
  background-color: rgba(0, 158, 129, 1);
}

.ui.button.green-clear{ 
  background-color: rgba(0, 158, 129, 0.1);
  border-color: rgba(0, 158, 129, 1);
  border-radius: 3;
  border-style: solid;
  border-width: 0.5px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
}

.ui.button.buttonStyle.red{ 
  background-color: rgba(204, 12, 12, 0.5);
}

.ui.dropdown.dropdownStyle{
  background-color: rgba(0, 158, 129, 0);
  color: rgba(255, 255, 255, 1);
  border-radius: 3;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 0.5px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.ui.dropdown.dropdownStyle:hover{
  background-color: rgba(255, 255, 255, 1);
  color: #000;
}

