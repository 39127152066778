.VR_ApplicationShow {
  text-align: center;
  display: flex;
  flex-direction: column;
  background-color: #282c34;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.pageBody1 {
  background-color: #287734;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  /* align-items: center;
  justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;  
}

.pageBodyVerticalHalf {
  background-color: #289934;
  width: 50%;
  min-height: 100vh;
  padding-top: 100px;
  font-size: calc(10px + 2vmin);
  color: white;  
}

.logoText {
  font-size: 15px;
  text-align: left;
}

.logoImage {
  width: 37.5px;
  height: 37.5px;
  border-radius: 8px;
  border-width: 2;
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 10px;
  margin-bottom: 25px;
  margin-top: 25px;
  border-color: rgb(255, 255, 255);
  border-style: solid;
}

.headerRowBox {
  display: flex;
  flex-direction: row;
  height: 175;
  width: 100%;
  padding-left: 15px;
  padding-top: 25px;
  background-color: #282c34;
}

.headerRowBoxNavBar {
  display: flex;
  flex-direction: row;
  width: 500px;
  align-items: center;
  justify-content: center;
  padding-left: 25px;
  padding-right: 25px;
  right: 115px;
  position: absolute;  /* background-color: #ffffff; */
}

.headerUsernameBox {
  /* background-color: rgb(0, 158, 129); */
  opacity: 1;
  border-radius: 8px;
  border-width: 2px;
  /* border-color: rgb(255, 255, 255); */
  /* border-style: solid; */
  height: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(7px + 2vmin);
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  right: 25px;
  position: absolute;
}

.navBarRouteButton {
  font-size: 15px;
  margin-left: 25px;
  margin-right: 25px;
  border-radius: 8px;
  border-width: 2px;
  width: 180px;
  height: 40px;
  border-color: rgb(255, 255, 255);
  border-style: solid;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* font-size: calc(3px + 2vmin); */
  color: white;
  text-align: left;
  padding-left: 20px;
  padding-right: 10px;
}

.logoBox {
  background-color: rgb(0, 158, 129);
  opacity: 1;
  border-radius: 8px;
  border-width: 2px;
  border-color: rgb(255, 255, 255);
  border-style: solid;
  height: 67.5px;
  width: 190px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(7px + 2vmin);
  color: white;
  left: 25px;
  position: absolute;
}

.loginBox {
  background-color: rgb(0, 158, 129);
  opacity: 1;
  border-radius: 8px;
  border-width: 2;
  border-color: rgb(255, 255, 255);
  border-style: solid;
  height: 275px;
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.container {
  font-family: arial;
  font-size: 24px;
  margin: 25px;
  width: 350px;
  height: 200px;
  outline: dashed 1px black;
}

.button {
  border: none;
  color: white;
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.loginInputLabel {
  font-size: 20px;
  text-align: left;
}

.loginInput{
  font-weight: 600;
  color: rgb(0, 158, 129);
  padding-left: 10;
  padding-right: 10;
  text-align: center;
  font-size: 16px;   /* if that’s what you want... */
  margin-top: 15px;
  width: 250px;
  height: 35px;
  border-radius: 8px;
  border-width: 2;
  border-color: rgb(255, 255, 255);
  border-style: solid;
}

.button1 {
  background-color: rgb(0, 158, 129);
  color: rgb(255, 255, 255);
  font-weight: 600;
  font-size: 20px;
  padding: 15px;
  height: 75px;
  width: 150px;
  border-radius: 8px;
  border: 2px solid rgb(255, 255, 255);
}

.button1_touched {
  opacity: 0.75;
  background-color: rgb(0, 158, 129);
  color: rgb(255, 255, 255);
  font-weight: 600;
  font-size: 20px;
  padding: 15px;
  height: 75px;
  width: 150px;
  border-radius: 8px;
  border: 2px solid rgb(255, 255, 255);
}

p {
  /* Center horizontally*/
  text-align: center;
}

a.button {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;

  text-decoration: none;
  color: initial;
}
.success-message{
  color: white;
  font-size: 1rems;
  text-align: left;
  margin-top: 10px;
  margin-left: 4px;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
